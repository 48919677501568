import React from "react";
// import Autocomplete from "react-autocomplete";
import Autocomplete from "react-select";
import userImg from "../../assets/img/avatars/default-user.png";
import _ from "lodash";
import GlobalSession from "../../views/Common/GlobalSession";
import axios from "axios";

const CompanyAndUserSelect = ({ userList, profile, fullWidth, mobile }) => {
  const updateEntityActivity = async (inputObj) => {
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/updateEntityActivity",
      data: inputObj,
    })
      .then((Response) => Response)
      .then((res) => {
        return res;
      })
      .catch(function (error) {
        console.log(error)
      });
  };
  const updateLogin = async (inputObj) => {
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/updateLogin",
      data: inputObj,
    })
      .then((Response) => Response)
      .then((res) => {
        return res;
      })
      .catch(function (error) {
        console.log(error)
      });
  };
  const loginHandler = async (tokenId, status) => {
    var randomnumber = Math.floor(Math.random() * 100);
    await axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_END_POINT +
        "/getEntityByToken/" +
        tokenId +
        "?v=" +
        randomnumber,
    })
      .then((Response) => Response)
      .then(async (res) => {
        if (sessionStorage.getItem("USER_ENTITY_ID") != null) {
          sessionStorage.removeItem("USER_ENTITY_ID");
        }
        if (localStorage.getItem("USER_ENTITY_ID") != null) {
          localStorage.removeItem("USER_ENTITY_ID");
        }
        if (res.data.header.errorCode > 0) {
          alert(res.data.header.errorMsg);
        } else {
          let inputobj = {
            auditActivityType: "UA",
            logType: "I",
            activity: "LOGIN_AS_USER",
            entityId: JSON.parse(localStorage.getItem("user_info")).entityId,
            growthPlanId: null,
            goalTagId: null,
            actionTagId: null,
            meetingId: null,
            page: "",
            auditText: "",
            auditValue: null,
            auditMessage: `You Logged In as User <${
              res.data.EntityUser && res.data.EntityUser.email
            }>`,
            auditLink: "",
            email: null,
          };
          let loginObj = {
            entityId: res.data.EntityUser && res.data.EntityUser.entityId,
            companyId: res.data.EntityUser && res.data.EntityUser.companyId,
            tokenId:
              Number(GlobalSession.entityId) === res.data.EntityUser.entityId
                ? res.data.EntityUser && res.data.EntityUser.tokenId
                : null,
            statusId: !!status ? 1 : 0,
          };
          localStorage.setItem(
            "USER_ENTITY_ID",
            res.data.EntityUser && res.data.EntityUser.entityId
          );
          localStorage.setItem(
            "USER_COMPANY_ID",
            res.data.EntityUser && res.data.EntityUser.companyId
          );
          localStorage.setItem(
            "USER_COMPANY_NAME",
            res.data.EntityUser && res.data.EntityUser.companyName
          );

          localStorage.setItem("tokenId", tokenId);
          sessionStorage.setItem(
            "USER_ENTITY_ID",
            res.data.EntityUser && res.data.EntityUser.entityId
          );

          sessionStorage.setItem(
            "IsPhotoUploadComplete",
            res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete
              ? res.data.EntityUser.isPhotoUploadComplete
              : ""
          );
          sessionStorage.setItem(
            "IsInterestsComplete",
            res.data.EntityUser && res.data.EntityUser.isInterestsComplete
              ? res.data.EntityUser.isInterestsComplete
              : ""
          );
          sessionStorage.setItem(
            "IsAddExpereinceComplete",
            res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete
              ? res.data.EntityUser.isAddExpereinceComplete
              : ""
          );
          sessionStorage.setItem(
            "IsCreateGrowthPlanComplete",
            res.data.EntityUser &&
              res.data.EntityUser.isCreateGrowthPlanComplete
              ? res.data.EntityUser.isCreateGrowthPlanComplete
              : ""
          );

          localStorage.setItem(
            "IsPhotoUploadComplete",
            res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete
              ? res.data.EntityUser.isPhotoUploadComplete
              : ""
          );
          localStorage.setItem(
            "IsInterestsComplete",
            res.data.EntityUser && res.data.EntityUser.isInterestsComplete
              ? res.data.EntityUser.isInterestsComplete
              : ""
          );
          localStorage.setItem(
            "IsAddExpereinceComplete",
            res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete
              ? res.data.EntityUser.isAddExpereinceComplete
              : ""
          );
          localStorage.setItem(
            "IsCreateGrowthPlanComplete",
            res.data.EntityUser &&
              res.data.EntityUser.isCreateGrowthPlanComplete
              ? res.data.EntityUser.isCreateGrowthPlanComplete
              : ""
          );

          window.IsPhotoUploadComplete =
            res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete
              ? res.data.EntityUser.isPhotoUploadComplete
              : "0";
          window.IsInterestsComplete =
            res.data.EntityUser && res.data.EntityUser.isInterestsComplete
              ? res.data.EntityUser.isInterestsComplete
              : "0";
          window.IsAddExpereinceComplete =
            res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete
              ? res.data.EntityUser.isAddExpereinceComplete
              : "0";
          window.IsCreateGrowthPlanComplete =
            res.data.EntityUser &&
            res.data.EntityUser.isCreateGrowthPlanComplete
              ? res.data.EntityUser.isCreateGrowthPlanComplete
              : "0";
          status
            ? localStorage.setItem("logingByPass", true)
            : localStorage.removeItem("logingByPass");

          sessionStorage.setItem("SHOW_USERPROFILE", true);
          GlobalSession.entityId =
            res.data.EntityUser && res.data.EntityUser.entityId;
          GlobalSession.companyId =
            res.data.EntityUser && res.data.EntityUser.companyId;
          GlobalSession.companyName =
            res.data.EntityUser && res.data.EntityUser.companyName;
          if (res.data.EntityUser.isAccountSetupComplete === 1) {
            localStorage.setItem("UserType", "ExistingUser");
            sessionStorage.setItem("UserType", "ExistingUser");
            window.UserType = "ExistingUser";
            !!status && (await updateEntityActivity(inputobj));
            await updateLogin(loginObj);
            window.location.href = !!mobile
              ? "#/mobile/dashboard"
              : "#/dashboard";
            window.location.reload();
          }
        }
      });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: !!fullWidth ? "100%" : "250px",
      borderRadius: "10px",
      borderColor: "#f0f0f0",
      borderSize: "1px",
      fontSize: "14px",
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : "none",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      backgroundColor: state.isFocused ? "#f0f0f0" : "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: () => ({ display: "none" }),
  };

  return (
    <div className="d-flex align-items-center">
      {
        // (profile.securityToken === 1 || profile.securityToken === 2)
        userList.length > 0 && (
          // <Autocomplete
          //   getItemValue={(item) => item.firstname + ' ' + item.lastname}
          //   items={userList.filter(
          //     (member) =>
          //       ((
          //         member.firstname.toLowerCase() &&
          //         member.companyName.toLowerCase()
          //       ).includes(inputName.toLowerCase()) ||
          //         member.firstname
          //           .toLowerCase()
          //           .includes(inputName.toLowerCase()) ||
          //         member.companyName
          //           .toLowerCase()
          //           .includes(inputName.toLowerCase())) &&
          //       member
          //   )}
          //   renderItem={(item, isHighlighted) => (
          //     <div
          //       key={item.id}
          //       className="d-flex p-2 border-bottom align-items-center"
          //       style={{
          //         background: isHighlighted ? "#f0f0f0" : "white",
          //         columnGap: "1rem",
          //         cursor: "pointer",
          //       }}
          //     >
          //       <img
          //         alt="icon"
          //         src={item.imageUri !== null ? item.imageUri : userImg}
          //         width="40px"
          //         height="40px"
          //         className="rounded-circle"
          //       />
          //       <div className="d-flex flex-column">
          //         <p className="p-0 m-0 text-dark" style={{ fontSize: "1rem" }}>
          //           {item.firstname + " " + item.lastname}
          //         </p>
          //         <p
          //           className="p-0 m-0 text-primary"
          //           style={{ fontSize: "0.8rem" }}
          //         >
          //           {item.companyName}
          //         </p>
          //       </div>
          //     </div>
          //   )}
          //   wrapperStyle={{
          //     position: "relative",
          //   }}
          //   menuStyle={{
          //     zIndex: 2,
          //     position: "absolute",
          //     top: 40,
          //     left: 0,
          //     overflowY: "auto",
          //     maxHeight: "300px",
          //   }}
          //   value={inputName || ""}
          //   onChange={(e) => {
          //     setInputName(e.target.value);
          //   }}
          //   onSelect={(name, index) => {
          //     setInputName(name);
          //     loginHandler(index.tokenId, true);
          //   }}
          //   inputProps={{
          //     placeholder: "Company and User name",
          //     className: "p-2 headerInput outline-0",
          //     style: { fontSize: 14, width: "250px", borderRadius: "10px" },
          //   }}
          // />
          <Autocomplete
            options={userList}
            isClearable={false}
            isSearchable={true}
            onChange={(selectedOption) =>
              loginHandler(selectedOption.tokenId, true)
            }
            getOptionLabel={(option) =>
              `${option.firstname} ${option.lastname} ${option.companyName}`
            }
            getOptionValue={(option) => option.id}
            formatOptionLabel={(option) => (
              <div
                key={option.id}
                className="d-flex p-2 border-bottom align-items-center"
                style={{
                  columnGap: "1rem",
                  cursor: "pointer",
                }}
              >
                <img
                  alt="icon"
                  src={option.imageUri !== null ? option.imageUri : userImg}
                  width="40px"
                  height="40px"
                  className="rounded-circle"
                />
                <div className="d-flex flex-column">
                  <p className="p-0 m-0 text-dark" style={{ fontSize: "1rem" }}>
                    {option.firstname + " " + option.lastname}
                  </p>
                  <p
                    className="p-0 m-0 text-primary"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {option.companyName}
                  </p>
                </div>
              </div>
            )}
            styles={customStyles}
            className="w-100"
            placeholder="Company and User name"
          />
        )
      }
      {
        <i
          className="fa fa-home ms-3"
          style={{
            display: `${
              !mobile && localStorage.getItem("logingByPass") !== null
                ? "flex"
                : "none"
            }`,
            cursor: "pointer",
            fontSize: "1.5rem",
            color: "#1898cd",
          }}
          onClick={() =>
            loginHandler(
              JSON.parse(localStorage.getItem("user_info")).tokenId,
              false
            )
          }
          aria-hidden="true"
        ></i>
      }
    </div>
  );
};

export default CompanyAndUserSelect;
